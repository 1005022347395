import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './content/components/notfound/notfound.component';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { AuthGuard } from '@auth0/auth0-angular';
import { SessionGuard } from './content/guards/auth.guard';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', redirectTo: 'orders', pathMatch: 'full'
            },
            { path: 'auth', loadChildren: () => import('./content/components/auth/auth.module').then(m => m.AuthCModule) },
            {
                path: '', component: AppLayoutComponent, 
                children: [
                    { path: '', loadChildren: () => import('./content/components/uikit/uikit.module').then(m => m.UIkitModule) },
                ],
                canActivate: [AuthGuard, SessionGuard],
            },
           
            { path: 'notfound', component: NotfoundComponent },
            { path: '**', redirectTo: '/orders', pathMatch: 'full' }, // Redirige cualquier URL no coincidente a /claims
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
