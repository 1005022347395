// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  domain:'dev-jqd5nrbe7t32x7of.us.auth0.com',
  clientId:'dZQnlCUGfPzeN7qne9tnXoiPCNNW5ta2',
  api:'https://7jn8tc4hkb.execute-api.us-east-1.amazonaws.com',
  sentryDns:'https://eee55cacf7f1198f7466dcaff44cd1b9@o4506322868895744.ingest.sentry.io/4506394474577920'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
