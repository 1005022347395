import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable, switchMap, take } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class DefaultInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return this.authService.getAccessTokenSilently().pipe(
      take(1),
      switchMap(idToken => {
        if (idToken) {
          request = request.clone({
            setHeaders: {
              // Authorization: `Bearer ${idToken}`
              Authorization: `da39a3ee5e6b4b0d3255bfef95601890afd80709`
            }
          });
        }

        return next.handle(request);
      })
    );
  }
}
