import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(public layoutService: LayoutService) { }

    ngOnInit() {
        this.model = [
            
            {
                label: 'Home',
                items: [
                    { label: 'Orders', icon: 'pi pi-fw pi-id-card', routerLink: ['/orders'] },
                    // { label: 'Products', icon: 'pi pi-fw pi-box', routerLink: ['/products'] },
                    // // { label: 'Sales', icon: 'pi pi-fw pi-wallet', routerLink: ['/sales'] },
                    // { label: 'History', icon: 'pi pi-fw pi-history', items: [
                    //     {
                    //         label: 'Orders',
                    //         icon: 'pi pi-fw pi-id-card',
                    //         routerLink: ['/histories/orders']
                    //     },
                    //     {
                    //         label: 'Products',
                    //         icon: 'pi pi-fw pi-box',
                    //         routerLink: ['/histories/products']
                    //     },
                    // ]},
                    { label: 'Log out', icon: 'pi pi-fw pi-sign-out', routerLink: ['/auth/logout'] }
                ]
            },
        ];
    }
}
